import {
  createTheme,
  DetailsRow,
  FontWeights,
  getTheme,
  IButtonStyles,
  IComboBoxOption,
  IDetailsListProps,
  IDetailsListStyles,
  IDetailsRowStyles,
  IIconProps,
  ITheme,
  mergeStyleSets,
  SelectableOptionMenuItemType,
} from "@fluentui/react";
import { IBlob, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { IPagination } from "../../interfaces/base/ITable";
import { Ifilter } from "../../interfaces/IFilter";
import { CSSProperties } from "react";

const theme = getTheme();
const keyJwt = "Jwt";
const keyAccess = "Access";

const dayBeforeReport = 7;

/* Palomino */
const EMPRESA_TITULAR_DEFAULT = "6A0004EE-AF54-49B3-B652-E666D89BDC88";
const baseSearch = "search";
const baseImageUrl =
  "http://www.consuladohondurasmadrid.es/wp-content/uploads/2017/05/male-300x300.png";
const LOGO_MMG =
  "https://marketing-image-production.s3.amazonaws.com/uploads/97b69fa34b6c08ce794c23897932e9b8f27254b6ee2f20df408cfd8d76c4f107007d66216c5b5a9041a8fc41db00a9f90f9b60494dee949a2d86754453e39baf.png";
const LOGO_TESEO = "https://teseo.idslatam.com/assets/img/teseoLogoAccount.png";

// validación politicas contraseña
const CONTIENE_NUMERO = /\d/;
const CONTIENE_MINUSCULA = /[a-z]/;
const CONTIENE_MAYUSCULA = /[A-Z]/;
const CONTIENE_NO_ALFANUMERICO = /[^a-zA-Z0-9]/;

/* Space */
const stackTokens = { childrenGap: 10 };
const stackTokens5 = { childrenGap: 5 };
const stackTokensHorizontal = { childrenGap: 10 };

const stackTokensSpace = {
  size5: { childrenGap: 5 },
  size10: { childrenGap: 10 },
  size15: { childrenGap: 15 },
  size20: { childrenGap: 20 },
  size25: { childrenGap: 25 },
};

const TAB_SUBIDA = "Subidas";
const TAB_BAJADA = "Bajadas";

const RedireccionUrlIdentidad = (
  urlBase: string,
  email: string,
  token: string,
  urlLogin: string
) => {
  const urlRedireccin = `${urlBase}/cambiarContrasenia?email=${email}&token=${token}&urlLogin=${urlLogin}`;
  return urlRedireccin;
};

const RedireccionUrlContraseniaIdentidad = (
  urlBase: string,
  email: string,
  token: string,
  urlLogin: string
) => {
  const urlRedireccion = `${urlBase}/CambiarContraseniaVigencia?email=${email}&token=${token}&urlLogin=${urlLogin}`;
  return urlRedireccion;
};

/* Filter */
const _initialFilter = () => {
  let d: Ifilter = {
    skip: 0,
    take: 50,
    search: null,
  };

  return d;
};

/* Pagination */
const _initialPagination = () => {
  let t: IPagination = {
    currentPage: 1,
    total: 0,
    pageSize: 50,
  };

  return t;
};

/* DetailList */
const _onRenderHeader: Partial<IDetailsListStyles> = {
  root: {
    overflowX: "scroll",
    selectors: {
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "60vh",
      },
    },
  },
  headerWrapper: {
    margin: "0 0 1px 0",
    paddingTop: "0px",
    boxShadow: theme.effects.elevation8,
    flex: "0 0 auto",
  },
  contentWrapper: {
    flex: "1 1 auto",
    overflowY: "auto",
    overflowX: "hidden",
  },
};

const _onRenderRow: IDetailsListProps["onRenderRow"] = (props: any) => {
  const customStyles: Partial<IDetailsRowStyles> = {};
  if (props) {
    customStyles.root = {
      width: "100%",
      margin: "0 0 0 0" /* boxShadow: theme.effects.elevation4 */,
    };
    return <DetailsRow {...props} styles={customStyles} />;
  }
  return null;
};

export const helperfontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif`;

export const helperFont: CSSProperties = {
  fontFamily: helperfontFamily,
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
};

/* Modal Style */
const modalStyle = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.medium,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const modalIconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const modalCancelIcon: IIconProps = { iconName: "Cancel" };

const warningButtonTheme: ITheme = createTheme({
  palette: {
    white: theme.palette.orangeLighter,
    neutralPrimary: "#fff",
  },
});

const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const splitExceptionDetail = (e: any) => {
  console.log(e);

  if (e.response.data === "") {
    return e.response.statusText;
  }

  const title = e.response?.data?.title ?? "";
  const detail = e.response?.data?.detail ?? "";

  return `${title}${title && detail ? ": " : ""}${detail}`;
};

/* Combo Sexo */
const optionSexo = () => {
  let t: IItemComboBaseDTO[] = [
    {
      key: "M",
      text: "Masculino",
    },
    {
      key: "F",
      text: "Femenino",
    },
  ];

  return t;
};

/* Combo opcion Si / No */
const optionBase = () => {
  let t: IItemComboBaseDTO[] = [
    {
      key: "1",
      text: "Si",
    },
    {
      key: "0",
      text: "No",
    },
  ];

  return t;
};

/* Funcion Descargar File Azure Blobs */
const downloadBlob = (f: IBlob) => {
  const link = document.createElement("a");
  link.href = f.url;
  link.download = `${f.nombre}.${f.extension}`;
  link.click();
};

const IndexNone = "-1";
const KeyAllSelect = "selectAll";

/* Opcion limpiar combo */
const ItemNoneSelect = () => {
  let t: IItemComboBaseDTO = {
    key: IndexNone,
    text: "NINGUNO",
  };

  return t;
};

const ItemSelectAll = () => {
  let t: IItemComboBaseDTO = {
    key: "selectAll",
    text: "SELECCIONAR TODOS",
    itemType: SelectableOptionMenuItemType.SelectAll,
  };

  return t;
};

/**Expresion regular */
const EXPRESION_EMAIL =
  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
const EXPRESION_CELULAR = /^[09][0-9]{8}$/;

/* Validate Object */
const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

/* Sorted */
const getSortedData = (data, prop, isAsc) => {
  return data.sort((a, b) => {
    return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1);
  });
};

/**Primera letra de cada palabra en MAYUSCULA */
export function stringToUpperCamelCase(text: string): string {
  if (text == null || undefined) return "";

  const palabras = text.toLocaleLowerCase().split(" ");

  let newText = palabras
    .map((palabra) => {
      return palabra[0].toUpperCase() + palabra.substring(1);
    })
    .join(" ");

  return newText;
}

//ComboMultiSelect: selecciona los items y superpone al inicio los seleccionados
const _selectionItemsKeys = (
  option: IComboBoxOption,
  setKeysCombo: React.Dispatch<React.SetStateAction<any[]>>,
  setlistObjetoCombo: React.Dispatch<React.SetStateAction<any[]>>,
  listObjetocombo: IItemComboBaseDTO[],
  keysCombo: any[]
) => {
  const selected = option?.selected;
  const currentSelectedOptionKeys = keysCombo.filter(
    (key) => key !== "selectAll"
  );
  const selectAllState =
    currentSelectedOptionKeys.length ===
    selectableOptionsCombo(listObjetocombo).length;

  if (option) {
    if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
      selectAllState
        ? setKeysCombo([])
        : setKeysCombo([
            "selectAll",
            ...selectableOptionsCombo(listObjetocombo).map(
              (o) => o.key as string
            ),
          ]);
    } else {
      const updatedKeys = selected
        ? [...currentSelectedOptionKeys, option!.key as string]
        : currentSelectedOptionKeys.filter((k) => k !== option.key);

      if (
        updatedKeys.length === selectableOptionsCombo(listObjetocombo).length
      ) {
        updatedKeys.push("selectAll");
      }

      let listUpdateKeys = updatedKeys.filter((x) => x !== "selectAll");
      let ListEstadoCombo = listObjetocombo.filter(
        (x) => x.key !== "selectAll"
      );
      let listMarcado: IItemComboBaseDTO[] = [];
      let listNoMarcado: IItemComboBaseDTO[] = [];

      for (let i = 0; i < ListEstadoCombo.length; i++) {
        let element = ListEstadoCombo[i].key;

        if (listUpdateKeys.includes(element)) {
          //console.log(`coincide '${element}'`)
          listMarcado.push(ListEstadoCombo[i]);
        } else {
          listNoMarcado.push(ListEstadoCombo[i]);
        }
      }

      listMarcado.sort((x, y) => x.text.localeCompare(y.text)); //ordenar por text

      let lista1UnionLista2 = listMarcado.concat(listNoMarcado);
      setKeysCombo(updatedKeys);
      lista1UnionLista2.unshift(ItemSelectAll());

      setlistObjetoCombo(lista1UnionLista2);
    }
  }
};

const selectableOptionsCombo = (keyCombo: IItemComboBaseDTO[]) => {
  let selectableOptions = keyCombo.filter(
    (option) =>
      (option.itemType === SelectableOptionMenuItemType.Normal ||
        option.itemType === undefined) &&
      !option.disabled
  );

  return selectableOptions;
};

const cambiarUrl = (urlLogin) => {
  let url = urlLogin?.replace("//", "@");
  return url?.replace(/\//g, "!");
};

const usuariosDominios = ["mmg.com"];

const validarDominio = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Validar el formato del email
  if (!emailRegex.test(email)) {
    return false;
  }
  // Obtener el dominio del email
  const dominio = email.split("@")[1];
  // Comprobar si el dominio está en la lista de dominios
  if (usuariosDominios.includes(dominio)) {
    return true;
  } else {
    return false;
  }
};

export {
  keyJwt,
  keyAccess,
  dayBeforeReport,
  EMPRESA_TITULAR_DEFAULT,
  baseSearch,
  baseImageUrl,
  LOGO_MMG,
  LOGO_TESEO,
  stackTokens,
  stackTokens5,
  stackTokensSpace,
  TAB_SUBIDA,
  TAB_BAJADA,
  stackTokensHorizontal,
  _initialFilter,
  _initialPagination,
  _onRenderHeader,
  _onRenderRow,

  /* Modal */
  modalStyle,
  modalIconButtonStyles,
  modalCancelIcon,

  /* Button */
  warningButtonTheme,

  /* File */
  getBase64,

  /* Exception */
  splitExceptionDetail,
  optionSexo,
  optionBase,

  /* Azure Blobs */
  downloadBlob,

  /* Opcion desmarcar combo */
  IndexNone,
  ItemNoneSelect,

  /* Opcion base seleccionar too */
  KeyAllSelect,
  ItemSelectAll,

  /**Expresion regular */
  EXPRESION_EMAIL,
  EXPRESION_CELULAR,
  CONTIENE_NUMERO,
  CONTIENE_MINUSCULA,
  CONTIENE_MAYUSCULA,
  CONTIENE_NO_ALFANUMERICO,

  /* Validate object */
  isObjectEmpty,

  /* Sorted */
  getSortedData,
  //selected ItemsCombo
  _selectionItemsKeys,
  // Gestion de usuarios
  cambiarUrl,
  RedireccionUrlIdentidad,
  RedireccionUrlContraseniaIdentidad,
  validarDominio,
};
